const theme = {
	images: {
		logo: "https://cdn2.infocasas.com.uy/web/5ee3722bdec05_infocdn__logo-infocasas@2x.png",
		isotipo: "https://cdn2.infocasas.com.uy/web/5fa45366ae1fb_infocdn__isotipo.png",
		favicon: "https://cdn2.infocasas.com.uy/web/5ee376daa7764_infocdn__favicon.ico",
		icono: "https://cdn2.infocasas.com.uy/web/5ee372edae68f_infocdn__app@2x.png",
	},
	fontFamily: "InfoCasasSans, system, -apple-system, Roboto, Segoe UI Bold, Arial, sans-serif",
	colors: {
		primaryColor: "#fc7b27",
		primaryHoverColor: "rgba(252, 123, 39, 0.2)",
		primaryDarkColor: "#DF6c19",
		secondaryColor: "#00BBAF",
		secondaryOpacityColor: "rgba(1,187, 175,0.25)",
		secondaryHoverColor: "#007882",
		textColor: "#3A4145",
		textSecondaryColor: "#808485",
		textInverseColor: "#a7a7a7",
		backgroundColor: "#fff",
		backgroundModalColor: "rgba(0, 0, 0, 0.5)",
		backgroundMenuColor: "#3A4145",
		linkColor: "#00BBAF", // == secondaryColor
		linkHoverColor: "#007882", // == secondaryHoverColor
		backgroundColorAternative: "#FAFAFA",
		borderColor: "#E9E9E9",
		favoriteColor: "#ff6767",
		successColor: "#69AD6C",
		errorColor: "#F24E1E",
		warningColor: "#fff3cd",
		placeholderTextColor: "#cccccc",
	},
	breakPoints: {
		xs: "576px",
		sm: "576px",
		md: "768px",
		lg: "992px",
		xl: "1200px",
		xxl: "1600px",
	},
	headerHeight: 64,
	spacing: {
		xsSpacing: 4,
		smSpacing: 8,
		mdSpacing: 12,
		lgSpacing: 16,
		xlSpacing: 24,
		xxlSpacing: 32,
	},
	fontSizes: {
		baseFontSize: "16px",
		baseLineHeight: 1.5,

		lgFontSize: "18px",
		smFontSize: "14px",
		xsFontSize: "12px",

		xlTitle: "32px",
		xlLineHeight: "40px",
		lgTitle: "28px",
		lgLineHeight: "36px",
		mdTitle: "24px",
		mdLineHeight: "32px",
		smTitle: "20px",
		smLineHeight: "28px",
		xsTitle: "18px",
		xsLineHeight: "24px",
	},
};

module.exports = theme;
