const theme = {
	images: {
		logo: "https://cdn2.casaseneleste.com/web/597a30fe83545_infocdn__logo-ceee.png",
		isotipo: "https://cdn2.infocasas.com.uy/web/60355fc5dd961_infocdn__ceee-isotipo.png",
		favicon: "https://cdn2.infocasas.com.uy/web/598dfeab9b197_infocdn__favicon.ico",
		icono: "https://cdn2.infocasas.com.uy/web/60355fc5dd961_infocdn__ceee-isotipo.png",
	},
	fontFamily: "Helvetica, system, -apple-system, sans-serif",
	colors: {
		primaryColor: "#f07238",
		primaryDarkColor: "#DF6C19",
		primaryHoverColor: "rgba(223, 108, 25, 0.5)",
		secondaryColor: "#2bb9e0",
		secondaryHoverColor: "#2bb9e0",
		secondaryOpacityColor: "rgba(43, 185, 224, 0.5)",
		textColor: "#4d4d4d",
		textSecondaryColor: "#808080",
		textInverseColor: "#a7a7a7",
		backgroundColor: "#fff",
		backgroundModalColor: "rgba(0, 0, 0, 0.5)",
		backgroundMenuColor: "#3A4145",
		linkColor: "#2bb9e0", // == secondaryColor
		linkHoverColor: "#5fd2db", // == secondaryHoverColor
		backgroundColorAternative: "#FAFAFA",
		borderColor: "#E9E9E9",
		favoriteColor: "#ff6767",
		successColor: "#69AD6C",
		errorColor: "#F24E1E",
		warningColor: "#fff3cd",
    placeholderTextColor: "#cccccc",
	},
	breakPoints: {
		xs: "576px",
		sm: "576px",
		md: "768px",
		lg: "992px",
		xl: "1200px",
		xxl: "1600px",
	},
	headerHeight: 64,
	spacing: {
		xsSpacing: 4,
		smSpacing: 8,
		mdSpacing: 12,
		lgSpacing: 16,
		xlSpacing: 24,
		xxlSpacing: 32,
	},
	fontSizes: {
		baseFontSize: "16px",
		baseLineHeight: 1.5,

		lgFontSize: "18px",
		smFontSize: "14px",
		xsFontSize: "12px",

		xlTitle: "32px",
		xlLineHeight: "40px",
		lgTitle: "28px",
		lgLineHeight: "36px",
		mdTitle: "24px",
		mdLineHeight: "32px",
		smTitle: "20px",
		smLineHeight: "28px",
		xsTitle: "18px",
		xsLineHeight: "24px",
	},
};

module.exports = theme;
