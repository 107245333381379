
export type ModalType = "LogIn" | "Register" | false;
export const EVENT_NAME = "EVENT_SHOW_LOGIN_MODAL";

export const openAuthModal = ({
	withPromise = false,
	defaultModalType = "LogIn",
	email = "",
}: {
	withPromise?: boolean;
	defaultModalType?: ModalType;
	email?: string;
}) => {
	if (withPromise) {
		return new Promise((accept, reject) => {
			if (
				typeof window !== "undefined" &&
				typeof window[EVENT_NAME] !== "undefined" &&
				window[EVENT_NAME]
			) {
				window.dispatchEvent(
					new CustomEvent(EVENT_NAME, {
						detail: {
							defaultModalType: defaultModalType,
							accept: accept,
							reject: reject,
							email,
						},
					})
				);
			} else {
				reject("WINDOW ISNT LISTENING TO " + EVENT_NAME);
			}
		});
	} else {
		window.dispatchEvent(
			new CustomEvent(EVENT_NAME, {
				detail: {
					defaultModalType: defaultModalType,
					accept: () => {},
					reject: () => {},
					email,
				},
			})
		);
	}
};