import React, { ReactNode, createContext, useEffect, useReducer } from "react";

import { Configs } from "./model";
import { gql, useQuery } from "@apollo/client";
import { CONFIG_QUERY } from "./ConfigsQuery";

const ConfigStateContext = createContext<Configs>({});
const ConfigDispatchContext = createContext(null);
export const defaultState: Configs = {
	country_id: null,
	main_domain: "www.infocasas.com.uy",
	site_name: "InfoCasas",
};

const reducer = (state: Configs, action): Configs => {
	switch (action.type) {
		case "set":
			return { ...action.payload };
		case "merge":
			return { ...state, ...action.payload };
		case "reset":
			return { ...defaultState };
		default:
			throw new Error("no funciona");
	}
};


const ConfigurationsProvider =  ({
	children,
	initialState = null,
	active,
}: {
	children: ReactNode;
	initialState: Configs;
	active: boolean;
}) => {
	const [state, dispatch] = useReducer(reducer, initialState ?? defaultState );
	const {data} = useQuery( gql`${CONFIG_QUERY}` ,{
		skip: !!initialState || state && state.country_id,
		ssr: false,
	})

	useEffect(() => {
		if(data?.configuration){
			dispatch({ type: "set", payload: { ...data.configuration } })
		}
	},[data])

	if (!active) return <>{children}</>;

	return (
		<ConfigDispatchContext.Provider value={dispatch}>
			<ConfigStateContext.Provider value={state}>{children}</ConfigStateContext.Provider>
		</ConfigDispatchContext.Provider>
	);
};

export { ConfigStateContext, ConfigDispatchContext, ConfigurationsProvider };
