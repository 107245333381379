import { useContext } from "react";
import { ConfigStateContext } from "../../Contexts/Configurations/context";
import { FiltersDispatchContext, FiltersStateContext } from "../../Contexts/Filters/context";
import { Filters, FiltersValues } from "../../Contexts/Filters/model";
import {
	isRent,
	isTemporal,
	propertiesTypeInTemporal,
	showBathrooms,
	showBedrooms,
	showDispositions,
	showFacilities,
	showFloors,
	showPropState,
	showRooms,
} from "../../Utils/Functions";

const getOnlyValues = (a): FiltersValues => {
	if (a == null || !a) return null;
	return Object.keys(a).reduce((acc, o) => {
		if (a[o] == null) {
			//acc[o] = null;
		} else if (Array.isArray(a[o])) {
			if (a[o].length > 0) {
				acc[o] = a[o].map(j => j.value);
			} else {
				//acc[o] = [];
			}
		} else if (a[o].value != null) {
			acc[o] = a[o].value;
		} else if (typeof a[o] == "string" && a[o] != "") {
			acc[o] = a[o];
		}
		return acc;
	}, {});
};

const sanitizeMapBound = (filters: FiltersValues): FiltersValues => {
	if (filters) {
		filters.map_bounds = filters?.map_bounds?.filter(point => point?.latitude && point?.latitude);
	}
	return filters;
};

const useFilters = () => {
	const filters = useContext(FiltersStateContext);
	const dispatch = useContext(FiltersDispatchContext);
	const { country_code } = useContext(ConfigStateContext);

	const values = sanitizeMapBound(getOnlyValues(filters));

	const filtersHasNewFilters = (newFilters: Filters) => {
		const newFiltersKeys = Object.keys(newFilters);

		const count = newFiltersKeys.reduce((accumulator, key) => {
			if (Array.isArray(newFilters[key]) || Array.isArray(filters[key])) {
				if (
					Array.isArray(newFilters[key]) &&
					newFilters[key].length > 0 &&
					Array.isArray(filters[key]) &&
					filters[key].length > 0 &&
					newFilters[key].length === filters[key].length
				) {
					const count2 = newFilters[key].reduce((accumulator2, currentValue) => {
						const found = filters[key].find(
							element2 =>
								currentValue.text === element2.text &&
								currentValue.value === element2.value
						);

						if (found) {
							accumulator2 += 1;
						}

						return accumulator2;
					}, 0);

					if (newFilters[key].length === count2) {
						return accumulator + 1;
					}
				}
			} else if (
				// (!newFilters[key] && !filters[key]) || //Se comenta esta línea porque genera un bug al togglear listado/mapa
				(newFilters[key] &&
					filters[key] &&
					newFilters[key].text === filters[key].text &&
					newFilters[key].value === filters[key].value)
			) {
				return accumulator + 1;
			}
		}, 0);

		return newFiltersKeys.length === count;
	};

	const changeFilters = (new_filters: Filters) => {
		if ("operation_type_id" in new_filters) {
			if (isTemporal(new_filters.operation_type_id.value)) {
				if (filters.property_type_id) {
					new_filters.property_type_id = filters.property_type_id.filter(e =>
						propertiesTypeInTemporal(e.value)
					);
				}
				new_filters.m2Min = null;
				new_filters.m2Max = null;
				new_filters.m2Type = null;
			}

			if (!isTemporal(new_filters.operation_type_id.value)) {
				new_filters.season = null;
				new_filters.dateFrom = null;
				new_filters.dateTo = null;
				new_filters.guests = null;
			}

			if (!isRent(new_filters.operation_type_id.value)) {
				new_filters.commonExpenses = null;
			}
		}

		if ("property_type_id" in new_filters) {
			let properties = new_filters.property_type_id.map(e => e.value);

			let operation = filters.operation_type_id.value;

			if (!showBedrooms(properties, operation, country_code)) {
				new_filters.bedrooms = null;
			}

			if (!showBathrooms(properties, operation)) {
				new_filters.bathrooms = null;
			}

			if (!showRooms(properties, operation, country_code)) {
				new_filters.rooms = null;
			}

			if (!showPropState(properties, operation)) {
				new_filters.constStatesID = null;
			}

			if (!showFloors(properties)) {
				new_filters.floors = null;
			}

			if (!showDispositions(properties)) {
				new_filters.dispositionID = null;
			}

			if (!showFacilities(properties, operation)) {
				new_filters.facilitiesGroup = null;
			}
		}

		if ("estate_id" in new_filters) {
			if (new_filters.estate_id == null) {
				new_filters.neighborhood_id = [];
			}
		}

		if (filtersHasNewFilters(new_filters)) {
			return null;
		}

		return dispatch({
			type: "merge",
			payload: { ...new_filters },
			country_code: country_code,
		});
	};

	return {
		filters: values,
		filtersTags: filters,
		changeFilters,
	};
};

export { useFilters };

