import { ApolloClient, ApolloError, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
	CURRENT_USER_QUERY,
	DELETE_USER_MUTATION,
	FORGOT_PASSWORD_MUTATION,
	GOOGLE_ONE_TAP_MUTATION,
	LOGIN_MUTATION,
	LOGOUT_MUTATION,
	REGISTER_MUTATION,
	SOCIAL_LOGIN_MUTATION,
	UPDATE_USER_MUTATION,
	USER_EXISTS_QUERY,
} from "./User.querys";
import React, { useContext, useEffect, useState } from "react";
import { UserDispatchContext, UserStateContext } from "../../Contexts/User/context";

type UserInput = { email: string; password: string; phone?: string };

interface UserHookInterface {
	user: {
		data: any;
		loading: boolean;
		error: ApolloError | undefined;
		refetch: Function;
		client: ApolloClient<any>;
	};
	isLoggedIn: boolean;
	userInput: {
		value: UserInput;
		set: ({ email, password, phone }: UserInput) => void;
	};
	login: { send: () => Promise<any>; response: any };
	logout: { send: () => Promise<any>; response: any };
	register: { send: () => Promise<any>; doLater: () => Promise<any>; response: any };
	userExists: { send: () => void; response: any; sendWithVariables: (x: any) => void };
	forgotPassword: { send: () => Promise<any>; response: any };
	socialLogin: { send: (x: any) => Promise<any>; response: any };
	oneTapSignIn: { send: (x: any) => Promise<any>; response: any };
	updateUser: { send: (x: any) => Promise<any>; response: any };
	requestDeleteUser: { send: () => Promise<any>; response: any };
	validationErrors: { errors: string[]; set: (x: string[]) => void };
}

const useUser = (callback = null): UserHookInterface => {
	const user = useContext(UserStateContext);
	const userDispatch = useContext(UserDispatchContext);

	const [queryMe, { data, called, loading, error, client }] = useLazyQuery(CURRENT_USER_QUERY, {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	});

	const refetch = () => queryMe();

	useEffect(() => {
		if (!!data?.me?.id) {
			userDispatch({ type: "set", payload: data });
			if (callback) {
				callback(data);
			}
		}
	}, [data]);

	const [userInput, setUserInput] = useState<UserInput>({
		email: "",
		password: "",
		phone: "",
	});

	const [validationErrors, setValidationErrors] = useState<string[]>([]);

	const errorHandler = (errors: ApolloError) => {
		let valErrors: string[] = [];
		errors.graphQLErrors.forEach(err => {
			if (err.extensions.category == "validation") {
				for (const [key, value] of Object.entries(err.extensions.validation)) {
					valErrors.push(value[0]);
				}
			} else if (err.extensions.category == "authentication") {
				valErrors.push(err.message);
			} else if (err.extensions.category == "WrongCountry") {
				valErrors.push(err.message);
			} else if (err.path[0] == "forgotPassword" && err.extensions.category == "NotFound") {
				valErrors.push(err.message);
			}
		});
		setValidationErrors(valErrors);
	};

	/* Log In */
	const [loginMutation, loginResponse] = useMutation(LOGIN_MUTATION, {
		onError: (errors: ApolloError) => errorHandler(errors),
	});

	const sendLogin = () => {
		setValidationErrors([]);
		return loginMutation({
			variables: {
				email: userInput.email,
				pass: userInput.password,
			},
		});
	};
	/* End Log In */

	/* Register */
	const [registerMutation, registerResponse] = useMutation(REGISTER_MUTATION, {
		onError: (errors: ApolloError) => errorHandler(errors),
	});

	const sendRegister = () => {
		setValidationErrors([]);
		return registerMutation({
			variables: {
				email: userInput.email,
				pass: userInput.password,
				phone: userInput.phone,
			},
		});
	};
	const sendFastRegister = () => {
		setValidationErrors([]);
		return registerMutation({
			variables: { email: userInput.email },
		});
	};
	/* End Register */

	/* User Exists */
	const [userExists, userExistsResponse] = useLazyQuery(USER_EXISTS_QUERY, {
		onError: (errors: ApolloError) => errorHandler(errors),
		fetchPolicy: "no-cache",
	});

	const sendUserExist = () => {
		setValidationErrors([]);
		return userExists({ variables: { email: userInput.email.replace(/\s/g, "") } });
	};

	const sendUserExistWithVariables = variables => userExists({ variables: { ...variables } });
	/* End User Exists */

	/* Social Login */
	const [loginSocial, socialResponse] = useMutation(SOCIAL_LOGIN_MUTATION, {
		onError: (errors: ApolloError) => errorHandler(errors),
	});
	const sendSocialLogin = variables => {
		setValidationErrors([]);
		return loginSocial({ variables: { ...variables } });
	};
	/* End Social Login */

	/* One Tap Sign In */
	const [MUTATOR_ONE_TAP_SIGN_IN, responseOneTapSignIn] = useMutation(GOOGLE_ONE_TAP_MUTATION);
	const sendOneTapSignIn = variables => MUTATOR_ONE_TAP_SIGN_IN({ variables: { ...variables } });
	/* End One Tap Sign In */

	/* Updata User */
	const [updateUser, responseUpdataUser] = useMutation(UPDATE_USER_MUTATION);
	const sendUpdateUser = variables => updateUser({ variables: { ...variables } });
	/* End Updata User */

	/* Delete User */
	const [deleteUser, responseDeleteUser] = useMutation(DELETE_USER_MUTATION);
	/* End Delete User */

	/* Forget Password */
	const [forgotPassword, forgotPasswordResponse] = useMutation(FORGOT_PASSWORD_MUTATION, {
		onError: (errors: ApolloError) => errorHandler(errors),
	});
	const sendForgotPassword = () => {
		setValidationErrors([]);
		return forgotPassword({ variables: { email: userInput.email } });
	};
	/* End Forget Password */

	/* Log Out */
	const [logoutMutation, logoutResponse] = useMutation(LOGOUT_MUTATION);
	/* End Log Out */

	return {
		user: {
			data: user,
			loading: loading,
			error: error,
			refetch,
			client,
		},
		isLoggedIn: !!user?.me?.id,
		userInput: {
			value: userInput,
			set: setUserInput,
		},
		login: {
			send: sendLogin,
			response: loginResponse,
		},
		logout: { send: logoutMutation, response: logoutResponse },
		register: {
			send: sendRegister,
			doLater: sendFastRegister,
			response: registerResponse,
		},
		userExists: {
			send: sendUserExist,
			sendWithVariables: sendUserExistWithVariables,
			response: userExistsResponse,
		},
		socialLogin: {
			send: sendSocialLogin,
			response: socialResponse,
		},
		oneTapSignIn: {
			send: sendOneTapSignIn,
			response: responseOneTapSignIn,
		},
		updateUser: {
			send: sendUpdateUser,
			response: responseUpdataUser,
		},
		forgotPassword: {
			send: sendForgotPassword,
			response: forgotPasswordResponse,
		},
		requestDeleteUser: {
			send: deleteUser,
			response: responseDeleteUser,
		},
		validationErrors: {
			errors: validationErrors,
			set: setValidationErrors,
		},
	};
};

export { useUser };

